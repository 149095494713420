
import SupportMessage from "@/components/SupportMessage"
import ProjectCard from "@/components/projects/ProjectCard"
import EvercamLoadingAnimation from "@evercam/shared/components/EvercamLoadingAnimation"
import ProjectsFilters from "@/components/projects/ProjectsFilters"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useAccountStore } from "@/stores/account"
import {
  AnalyticsEvent,
  AnalyticsEventPageId,
} from "@evercam/shared/types/analytics"
import { useNuxtApp } from "#app"

export default {
  meta: {
    pageId: AnalyticsEventPageId.AllProjects,
  },
  components: {
    ProjectCard,
    ProjectsFilters,
    EvercamLoadingAnimation,
    SupportMessage,
  },
  middleware({ redirect }) {
    if (!useAccountStore().token) {
      return redirect("/v2/users/signin")
    }
  },
  head() {
    return {
      title: "Evercam | Projects Dashboard",
      meta: [
        { charset: "utf-8" },

        {
          hid: "description",
          name: "description",
          content: "TIME-LAPSE & PROJECT MANAGEMENT CAMERAS",
        },
      ],
    }
  },
  computed: {
    ...mapStores(useProjectStore),
    isOffline() {
      return useNuxtApp().vue2App.isOffline
    },
  },
  mounted() {
    this.$analytics.saveEvent(AnalyticsEvent.PageView)
  },
}
