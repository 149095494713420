
import { CameraFeatureFlag, ProjectFeatureFlag } from "@evercam/shared/types"
import FeaturesSelector from "@/components/projects/FeaturesSelector"
import { mapStores } from "pinia"
import { useProjectStore } from "@/stores/project"
import { useCameraStore } from "@/stores/camera"

export default {
  components: {
    FeaturesSelector,
  },
  props: {
    value: {
      type: String,
      default: () => "",
    },
  },
  data() {
    return {
      search: "",
      selectedFeature: null,
      selectedCameraFeatures: [],
      selectedProjectFeatures: [],
      projectFeatureFlags: [
        { name: "360 View", value: ProjectFeatureFlag.ThreeSixtyView },
        { name: "BIM", value: ProjectFeatureFlag.Bim },
        { name: "Drone View", value: ProjectFeatureFlag.DroneView },
        { name: "Gate Report", value: ProjectFeatureFlag.GateReport },
        {
          name: "Gate Report - ANPR based",
          value: ProjectFeatureFlag.GateReportAnprBased,
        },
        { value: ProjectFeatureFlag.AnprLegacy, name: "Anpr Legacy" },
        {
          name: "Media Hub Sharing",
          value: ProjectFeatureFlag.MediaHubSharing,
        },
      ],
      cameraFeatureFlags: [
        { name: "Edge video", value: CameraFeatureFlag.EdgeVideo },
        { name: "ANPR", value: CameraFeatureFlag.ANPR },
        { name: "BIM Compare", value: CameraFeatureFlag.BimCompare },
        { name: "PTZ", value: CameraFeatureFlag.PTZ },
        { name: "Video Stream", value: CameraFeatureFlag.VideoStream },
        {
          name: "WebRTC",
          value: CameraFeatureFlag.WebRTC,
        },
        {
          name: "Timelapse reports",
          value: CameraFeatureFlag.CopilotTimelapseReport,
        },
        {
          name: "Segmentation",
          value: CameraFeatureFlag.Segmentation,
        },
        {
          name: "Segmentation Auto-Labelling",
          value: CameraFeatureFlag.SegmentationAutoLabelling,
        },
      ],
    }
  },
  computed: {
    ...mapStores(useProjectStore, useCameraStore),
    canApplyFeaturesFilters() {
      return (
        this.selectedProjectFeatures.length ||
        this.selectedCameraFeatures.length
      )
    },
    canApplyQueryFilters() {
      return this.search && this.search.length >= 3
    },
    canApplyFilters() {
      return this.canApplyQueryFilters || this.canApplyFeaturesFilters
    },
  },
  watch: {
    search() {
      this.applyFilters()
    },
    selectedCameraFeatures() {
      this.applyFilters()
    },
    selectedProjectFeatures() {
      this.applyFilters()
    },
  },
  mounted() {
    this.$addEventListener("keydown", this.onKeyHandler)
    this.$addEventListener("keyup", this.onKeyHandler)
  },
  beforeDestroy() {
    this.resetSearchFields()
  },
  methods: {
    applyFilters() {
      this.projectStore.filteredProjects = this.projectStore.projects

      if (!this.canApplyFilters) {
        return
      }

      this.filterProjectsByFeatures()

      if (!this.canApplyQueryFilters) {
        return
      }

      this.filterProjectsByQuery()
    },
    getCamerasHasSelectedFeatures(selectedFeatures) {
      const filteredCameras = this.cameraStore.cameras.reduce((acc, camera) => {
        const hasAllSelectedFeatures = selectedFeatures.every((feature) =>
          camera.featureFlags.includes(feature)
        )

        if (hasAllSelectedFeatures) {
          acc = [...acc, camera]
        }

        return acc
      }, [])

      return filteredCameras
    },
    hasProjectSelectedFeatures(projectFeatures) {
      return this.selectedProjectFeatures?.every((feature) =>
        projectFeatures?.includes(feature)
      )
    },
    hasFilteredCamerasMatchingProjectExid(filteredCameras, projectId) {
      return filteredCameras.some(
        (camera) => camera.project.id.toLowerCase() === projectId.toLowerCase()
      )
    },
    filterProjectsByQuery() {
      const isInQuery = (word) =>
        word.toLowerCase().includes(this.search.toLowerCase())

      const filteredCameras = (this.cameraStore.cameras || []).filter(
        (camera) => isInQuery(camera.name) || isInQuery(camera.id)
      )

      this.projectStore.filteredProjects =
        this.projectStore.filteredProjects.filter(
          (project) =>
            isInQuery(project.name) ||
            isInQuery(project.exid) ||
            this.hasFilteredCamerasMatchingProjectExid(
              filteredCameras,
              project.exid
            )
        )
    },
    filterProjectsByFeatures() {
      if (!this.canApplyFeaturesFilters) {
        return
      }

      if (this.selectedProjectFeatures.length) {
        this.projectStore.filteredProjects =
          this.projectStore.filteredProjects.filter((project) =>
            this.hasProjectSelectedFeatures(project.featureFlags)
          )
      }

      if (!this.selectedCameraFeatures.length) {
        return
      }

      const filteredCameras = this.getCamerasHasSelectedFeatures(
        this.selectedCameraFeatures
      )

      if (!filteredCameras.length) {
        this.projectStore.filteredProjects = []

        return
      }

      this.projectStore.filteredProjects =
        this.projectStore.filteredProjects.filter((project) =>
          this.hasFilteredCamerasMatchingProjectExid(
            filteredCameras,
            project.exid
          )
        )
    },
    onKeyHandler(e) {
      const ctrlKey = e.ctrlKey && e.type === "keydown"
      const shiftKey = e.shiftKey && e.type === "keydown"
      const fKey = ["f", "F"].includes(e.key) && e.type === "keydown"
      const esc = e.key === "Escape" && e.type === "keydown"

      if (
        fKey &&
        ctrlKey &&
        shiftKey &&
        !this.projectStore.searchProjectEnabled
      ) {
        this.projectStore.searchProjectEnabled = true
        this.$nextTick(() => {
          const searchField = this.$refs.searchField
          searchField && searchField.focus()
          this.$emit("resize")
        })
      }
      if (esc) {
        this.resetSearchFields()
      }
    },
    resetSearchFields() {
      this.projectStore.searchProjectEnabled = false
      this.search = ""
      this.selectedCameraFeatures = []
      this.selectedProjectFeatures = []
      this.projectStore.filteredProjects = this.projectStore.projects
    },
  },
}
