
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    label: {
      type: String,
      default: "All Features",
    },
    allItemsLabel: {
      type: String,
      default: "All Features",
    },
  },
  computed: {
    selectedFeatures: {
      get() {
        return this.value
      },
      set(values) {
        this.$emit("input", values)
      },
    },
    isAllFeaturesSelected() {
      return (
        this.selectedFeatures &&
        this.selectedFeatures.length == this.items.length
      )
    },
  },
  methods: {
    toggleAllFeatures() {
      if (this.isAllFeaturesSelected) {
        this.selectedFeatures = []

        return
      }
      this.selectedFeatures = this.items.map((feature) => feature.value)
    },
  },
}
